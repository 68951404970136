import React from 'react';

function HomepageFeatured() {
  return (
    <section className='ptb-50'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12 '>
            <div className='join-mission blink_me mtb-15'>
              <h2>
                We've noticed that our name is being misused for trading scams.
                We have no links to such activities. Our work is solely focused
                on free social service. Stay cautious and avoid scams.
              </h2>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-8 mx-auto'>
            <div className='join-mission'>
              <h2>Joining our mission is the most impactful way to give!</h2>
            </div>
          </div>
        </div>
        <div className='icon-join'>
          <div className='row'>
            <div className='col-md-3'>
              <div className='icon-flex'>
                <div className='icon-image'>
                  <img src='assets/images/volunteer 1.png' />
                </div>
                <div className='icon-content'>
                  <p>
                    To facilitate access to basic healthcare to every section of
                    the society.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='icon-flex'>
                <div className='icon-image'>
                  <img src='assets/images/volunteer2.png' />
                </div>
                <div className='icon-content'>
                  <p>All you need is the will to have a greater nation!</p>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='icon-flex'>
                <div className='icon-image'>
                  <img src='assets/images/volunteer3.png' />
                </div>
                <div className='icon-content'>
                  <p>Be the change you wish to see!</p>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='icon-flex'>
                <div className='icon-image'>
                  <img src='assets/images/volunteer4.png' />
                </div>
                <div className='icon-content'>
                  <p>Empower every person to drive a new era of development.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomepageFeatured;
